import React from "react";
import "./styles/Banner.css";
const Banner = () => {
    return (
        <div className="banner">
            <div className="banner_text">Legislative Instrument</div>
        </div>
    );
};

export default Banner;
